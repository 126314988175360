import React from "react"
import PropTypes from "prop-types"
import Section from "../components/Section"

const Frame = ({children, ...props}) => {
  return (
    <>
      <div className="hcc-sticky-filters-divider">
        <hr/>
      </div>
      <Section className="hcc-sticky-filters" data-datocms-noindex>
        {children}
      </Section>
    </>
  )
}

Frame.defaultProps = {
  children : null,
}

Frame.propTypes = {
  children : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Frame
